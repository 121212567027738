/* Estilos básicos */
.main-layout {
  display: flex;
  flex-direction: column; /* Adicionado para melhor suporte em mobile */
  height: 100vh; /* Garante que o layout ocupe toda a altura da viewport */
}

.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s ease, margin-right 0.3s ease;
  margin-left: 250px; /* Para layouts maiores */
  margin-right: 20px;
  padding: 20px;
}

/* Margem para layouts maiores */
.main-content.expanded {
  margin-left: 250px;
}

.main-content.collapsed {
  margin-left: 80px;
}

/* Ajustes para dispositivos móveis */
@media (max-width: 768px) {
  .main-content {
    margin-left: 10px; /* Adiciona margem à esquerda */
    margin-right: 10px; /* Adiciona margem à direita */
    padding: 10px;
    /* Ajusta o layout em dispositivos móveis */
    margin: 0 auto; /* Centraliza o conteúdo horizontalmente */
  }

  .main-content.expanded {
    margin-left: 10px; /* Adapta a margem esquerda para estado expandido */
  }

  .main-content.collapsed {
    margin-left: 10px; /* Adapta a margem esquerda para estado colapsado */
  }
}

@media (max-width: 480px) {
  .content {
    padding: 20px 10px; /* Ajuste de padding para dispositivos móveis menores */
  }
}
