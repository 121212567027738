.profile-container {
    text-align: center;
    padding: 20px;
}

.profile-picture-container {
    position: relative;
    display: inline-block;
}

.profile-picture {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}

.default-profile-picture {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    color: #ccc;
}

.edit-button {
    position: absolute;
    bottom: -15px; 
    left: 50%;
    transform: translateX(-50%);
    background-color: #007bff;
    color: white;
    padding: 10px 20px; 
    cursor: pointer;
    border-radius: 5px;
    width: 150px;
    text-align: center; 
}

.inde-edit-profile-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.username-input {
    padding: 10px;
    margin: 10px 0;
    font-size: 16px;
    width: 200px; 
}

.update-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.admin-label-container {
    margin-top: 10px;
    margin-top: 50px;
}

.admin-label {
    display: inline-block;
    padding: 5px 10px;
    background-color: #f0f0f0;
    color: #333;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    width: 300px; 
    text-align: center;
}
