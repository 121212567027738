.app-container {
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .icons-container {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-top: 30px;
  }
  
  .icon {
    cursor: pointer;
    text-align: center;
  }
  
  .icon p {
    margin-top: 10px;
    font-size: 16px;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 30px;
    border-radius: 5px;
    text-align: center;
  }
  
  .modal button {
    margin: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .ticket-box {
    margin-top: 20px;
    padding:17px;
    border: 2px solid #000;
    display: inline-block;
    background-color: #f9f9f9;
  }
  